import { lazy, Suspense, useEffect, useState } from 'react';

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { useAuthStore } from 'features/Auth/storage';
import { LoadingScreen } from 'screens/Loading'

const Home = lazy(() => import('screens/Home'));
const Login = lazy(() => import('screens/Login'));


export function Routes() {
  const Layout = () => {
    const { isAuthenticated } = useAuthStore()
    const [isLogged, setisLogged] = useState(isAuthenticated);
  
    useEffect(() => {
      setisLogged(isAuthenticated);
    }, [isAuthenticated]);
  
    return (
      <Suspense fallback={<LoadingScreen />}>
        {isLogged ? <Home /> : <Login />}
      </Suspense>
    );
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
    },
  ])

  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}
